.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  padding: 10px 0px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: 36px;
  top: 4px;
}
.react-datepicker-popper {
  z-index: 100;
}
