.social-links {
  justify-self: flex-end;
}
.social-links i {
  font-size: 20px;
  color: $accent-color-dark;
  display: inline-block;
  margin-left: 12px;
}
.social-links i:hover {
  transition: all 300ms linear;
  transform: scale(1.15);
}
@media only screen and (max-width: 800px) {
  .social-media-btns {
    flex-direction: column;
  }
  .social-media-btns > div {
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
  }
}
