.dropzone > div {
  background-color: transparent !important;
  border: none !important;
  width: auto !important;
  height: auto !important;
}
.dropzone .error {
  color: $error-color;
}
.dropzone.disabled {
  pointer-events: none;
  opacity: 0.5;
}
@media only screen and (max-width: 560px) {
  .dropzone {
    margin-bottom: 16px;
  }
}
