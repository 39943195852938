/***** ALL COURSES STYLES *****/
/*** Header Styles ***/
.all-courses-header .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.all-courses-header .custom-checkbox[type="checkbox"] + label {
  white-space: nowrap;
}
@media only screen and (max-width: 1040px) {
  .courses-category-select .input-container-select__control {
    min-height: 32px;
    height: 32px;
    min-width: 160px;
    border: none;
  }
  .courses-category-select .input-container-select__single-value {
    color: $accent-color;
  }
  .courses-category-select .input-container-select__indicator-separator {
    display: none;
  }
  .courses-category-select .input-container-select__indicator {
    padding: 0 8px;
  }
  .courses-category-select .input-container-select__indicator svg {
    fill: accent-color;
    height: 16px;
  }
}
/*** Filter Sidebar Styles ***/
.course-category-filter {
  min-width: 216px;
}
.course-category-filter .link-text-secondary.active {
  color: $accent-color;
  border-bottom: 2px solid $accent-color;
}
/*** Course Cards Styles ***/
.all-courses-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(268px, calc(33% - 8px)));
  grid-gap: 24px 16px;
}
.course-card.card {
  padding: 16px;
  position: relative;
}
.course-card > a {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.course-card .jli-logo {
  padding-top: 8px;
  padding-bottom: 8px;
}
.course-card .gradient-img-container {
  margin: -16px -16px 16px -16px;
  width: calc(100% + 32px);
  height: 96px;
  object-fit: cover;
  border-radius: 3px 3px 0 0;
}
.course-card .gradient-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px 3px 0 0;
}
.course-card .new-course-tag {
  position: absolute;
  top: 16px;
  left: 16px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 1px;
  text-transform: uppercase;
  padding: 2px 4px;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.2);
}
.all-courses-cards-grid .courses-category-header {
  grid-column: 1/-1;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  color: $accent-text;
}
.all-courses-cards-grid .pagination-footer {
  grid-column: 1/-1;
}
@media only screen and (max-width: 1040px) {
  .all-courses-cards-grid {
    grid-template-columns: repeat(auto-fit, minmax(340px, calc(50% - 8px)));
  }
}
@media only screen and (max-width: 600px) {
  .all-courses-cards-grid {
    grid-template-columns: 1fr;
  }
  .all-courses-cards-grid .pagination-footer {
    padding: 0 16px;
  }
}
/***** MY COURSES STYLES *****/
/*** Course Card Styles ***/
.my-course-card {
  display: flex;
  max-width: 960px;
  height: 160px;
  margin-bottom: 40px;
  padding: 24px;
  overflow: hidden;
}
.my-course-card .gradient-img-container {
  width: 400px;
  height: 160px;
  padding-right: 32px;
  padding-bottom: 0px;
  border-radius: 3px 3px 0 0;
  margin: -24px;
}
.my-course-card .gradient-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px 0 0 3px;
}
.my-course-card-info {
  width: 100%;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.my-course-card .course-card-footer {
  margin: 0;
  padding: 0;
}
.hidden {
  visibility: hidden;
}
.text-registered {
  color: #ffc000;
}
.text-completed {
  color: #63c674;
}
.text-incomplete {
  color: #f06262;
}
.text-pending {
  color: #f26522;
}
.text-ineligible {
  opacity: 0.5;
  color: #000000;
}
@media only screen and (max-width: 1024px) {
  .my-course-card {
    height: auto;
  }
  .my-course-card .gradient-img-container {
    margin: 0;
    border-radius: 0;
  }
  .my-course-card .gradient-img-container img {
    border-radius: 0;
  }
}
@media only screen and (max-width: 600px) {
  .my-course-card {
    display: block;
    padding: 16px;
  }
  .my-course-card .gradient-img-container {
    width: calc(100% + 32px);
    margin: -16px -16px 16px -16px;
    height: auto;
    padding-right: 0;
  }
  .my-course-card-info {
    margin-left: 0;
  }
}
.full-page-loader.my-courses-loader {
  height: calc(100vh - 180px);
}

/***** COURSE DETAILS STYLES *****/
/*** Header Styles ***/
.course-details-header {
  position: relative;
  color: #fff;
  margin-bottom: 40px;
  padding: 40px 24px;
  min-height: 360px;
  overflow: hidden;
}
.course-details-header-img-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.course-details-header img {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.course-details-header-img-container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}
.course-details-header .relative.container {
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.course-details-header .course-category {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 24px;
}
.course-details-header .course-title {
  font-size: 48px;
  font-weight: 700;
}
@media only screen and (max-width: 1040px) {
  .course-details-header {
    padding: 40px 0;
    min-height: 220px;
  }
  .course-details-header .relative.container {
    min-height: unset;
  }
  .course-details-header .course-category {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .course-details-header .course-title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .course-details-header {
    padding: 24px 0 16px 0;
    min-height: unset;
  }
  .course-details-header .medium-text.link-text-secondary {
    font-size: 14px;
  }
  .course-details-header .medium-text.uppercase-text {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .course-details-header .course-title {
    font-size: 24px;
  }
}
/*** Sidebar Styles ***/
.course-details-sidebar {
  min-width: 200px;
  width: 200px;
  margin-right: 80px;
}
@media only screen and (max-width: 1040px) {
  .course-details-sidebar {
    margin-right: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .course-details-sidebar {
    width: 100%;
  }
}
/*** Divider Styles ***/
.mobile-course-details-divider {
  border-bottom: 1px solid #ebecf2;
  margin: 16px -16px 24px;
}
/*** Course Details Section Styles ***/
.course-details-section-title {
  font-weight: 700;
  line-height: 1.5;
  font-size: 24px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1040px) {
  .course-details-section-title {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
/* Locations Styles */
@media only screen and (max-width: 1040px) {
  .course-details-section .my-location-tag {
    margin-left: 0;
    margin-top: 8px;
  }
}
/* Classes Styles */
.course-details-classes {
  margin-top: 40px;
  padding-left: 56px;
}
.course-details-classes .class-number {
  background: #fff;
  border: 2px solid #1326f3;
  height: 32px;
  width: 32px;
  position: absolute;
  left: -56px;
  color: #f26522;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.course-details-classes > div:not(:last-child) .class-info:before {
  content: "";
  height: calc(100% + 32px);
  width: 2px;
  background: $accent-color;
  position: absolute;
  left: -40px;
}
.course-details-classes .class-info {
  position: relative;
  top: 8px;
}
.course-details-classes .class-info .class-date {
  height: 16px;
}
.course-details-classes .class-info .class-date.grid {
  display: grid;
  grid-template-columns: 176px 144px;
  align-items: flex-end;
}
.course-details-classes .class-info .class-date p:last-child {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.accept-course-terms-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
.course-details-classes .class-info .class-title {
  margin-right: 16px;
}
@media only screen and (max-width: 1040px) {
  .course-details-classes .class-info .class-date {
    height: unset;
    margin-top: 16px;
    flex-wrap: wrap;
  }
  .course-details-classes .class-info .class-date .medium-text {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .course-details-classes .class-info .class-title {
    margin-right: 0px;
  }
}
/* Select Schedule Styles */
.course-schedule-select {
  margin-top: 16px;
  width: 480px;
  padding: 8px 8px 4px 16px;
}
.course-schedule-select .input-container-select {
  margin-top: 8px;
}
.course-schedule-select .input-container-select__control {
  border: none;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: flex-start;
}
.input-container-select__control--is-focused {
  box-shadow: none !important;
  border-color: #edecec !important;
}
.course-schedule-select .input-container-select__value-container {
  padding: 0;
}
.course-schedule-select .custom-select > div:first-of-type {
  height: 24px;
}
.course-schedule-select .input-container-select__indicators {
  height: 24px;
}
.course-schedule-select .input-container-select__indicator-separator {
  display: none;
}
.course-schedule-select .input-container-select__option--is-selected {
  background-color: $accent-color;
}
