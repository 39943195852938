.status-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 240px);
  padding-bottom: 8vh;
}
.status-message-container p:first-of-type {
  max-width: 420px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 24px;
}
