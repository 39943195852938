/***** FOOTER STYLES *****/
/*** Footer Top Styles ***/
.footer-top {
  height: auto;
  min-height: 240px;
  background: #1326f3;
  color: #fff;
  padding: 40px 64px;
}
.footer-top .container {
  display: grid;
  grid-template-columns: 48px 5fr 4fr 3fr 162px;
  grid-gap: 80px;
}
@media only screen and (max-width: 1040px) {
  .footer-top {
    padding: 40px 0;
    height: auto;
  }
  .footer-top .container {
    grid-template-columns: 48px auto auto auto;
    grid-gap: 24px 48px;
  }
  .social-links {
    grid-column-start: 2;
    grid-column-end: 3;
    justify-self: flex-start;
  }
  .social-links img {
    margin-right: 0;
    margin-left: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .footer-top {
    height: unset;
    padding: 24px 16px;
  }
  .footer-top .container {
    grid-template-columns: 48px 1fr;
    grid-gap: 40px;
  }
  .footer-top .container > p:first-of-type {
    margin-top: -24px;
  }
  .footer-top .container > p:nth-child(2) {
    grid-column-start: span 2;
    order: 2;
  }
  .footer-top .container > div:nth-child(3),
  .footer-top .container > div:nth-child(4) {
    grid-column-start: span 2;
    order: 3;
  }
  .footer-top .social-links {
    order: 1;
    justify-self: flex-end;
  }
}
/* Column Titles Styles */
.footer-column-title:after {
  content: "";
  height: 2px;
  width: 40px;
  position: relative;
  bottom: -8px;
  background: #fff;
  display: block;
}
/* Footer Nav Links */
.footer-nav-links .nav-link > a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 0;
}
.footer-nav-links .active-nav-link {
  color: #fff;
}
/* Social Link Styles */
.social-links img {
  height: 24px;
  margin-left: 32px;
}
/*** Footer Bottom Styles ***/
.footer-bottom {
  height: 64px;
}
@media only screen and (max-width: 1200px) {
  .footer-bottom > div:last-child {
    margin-right: 48px;
  }
}
