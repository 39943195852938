/***** ACCOUNT PAGES STYLES *****/
.account-page {
  min-height: 100vh;
  background: #ebecf2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}
.account-page > a {
  display: inherit;
}
@media only screen and (max-width: 600px) {
  .account-page {
    padding: 24px 0 0;
  }
}
.logo {
  height: 64px;
  width: 52px;
}
/*** Account Card Styles ***/
.account-card {
  width: 640px;
  min-height: 280px;
  max-width: 98%;
  padding: 40px;
}
.account-card .btn-fullWidth {
  max-width: 416px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media only screen and (max-width: 600px) {
  .account-card {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    padding: 40px 16px 88px;
    min-height: calc(100vh - 112px);
  }
}

/***** LOGIN PAGE STYLES *****/
/*** Tab Styles ***/
.tabs {
  margin: -40px -40px 24px;
  display: flex;
}
.tab {
  width: 50%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  background: #f7f7fa;
  transition: all 300ms linear;
}
.tab.active {
  background: #fff;
  border-top: 2px solid $accent-color;
}
.tab:not(.active):hover {
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .tabs {
    margin: -40px -16px 24px;
  }
  .tab {
    font-size: 13px;
  }
}
/*** Password Reset Success Message ***/
.reset-password-success {
  color: #63c674;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .reset-password-success {
    align-items: flex-start;
  }
}
/*** Text Styles ***/
.create-account-text {
  max-width: 400px;
  margin: 0 auto;
}
@media only screen and (max-width: 600px) {
  .create-account-text {
    max-width: 280px;
  }
}
/*** Social Login Buttons ***/
.login-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebecf2;
  padding: 0 40px 40px;
  margin: 24px -40px 40px;
}
.login-btns > * {
  width: 50%;
  max-width: 240px;
}
@media only screen and (max-width: 600px) {
  .login-btns {
    flex-direction: column;
    padding: 0 16px 32px;
    margin: 24px -16px;
  }
  .login-btns > * {
    width: 100%;
    max-width: 280px;
  }
}
// /* Google Sign In Button Styles --> REMOVED LIB */
// .g-signin2 {margin-right: 16px;}
// .g-signin2 .abcRioButton {
//   width: 100% !important;
//   height: 48px !important;
//   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 0 5px -2px rgba(0,0,0,0.12), 0 2px 18px 2px rgba(0,0,0,0.08) !important;
//   border: 1px solid rgba(0, 0, 0, .04);
//   border-radius: 3px !important;
// }
// .g-signin2 .abcRioButton:hover {box-shadow: none !important; transition: box-shadow 300ms linear;}
// .g-signin2 .abcRioButtonContentWrapper {display: flex; height: 100%; align-items: center; padding-left: 16px; padding-right: 32px;}
// .g-signin2 .abcRioButtonContentWrapper > span {line-height: 16px; max-width: calc(100% - 32px); text-align: left; font-family: Graphik; margin-left: 0;}
// .g-signin2 .abcRioButtonIcon {padding: 0 !important; margin-right: 8px;}
// .g-signin2 .abcRioButtonIcon > div,  .g-signin2 .abcRioButtonIcon > div svg {height: 24px !important; width: 24px !important;}
// .g-signin2 .abcRioButtonContents {font-weight: normal; color: rgba(0, 0, 0, .87);}
// @media only screen and (max-width: 624px){
//   .g-signin2 .abcRioButtonContents {font-size: 13px !important;}
// }
// @media only screen and (max-width: 600px){
//   .g-signin2 {margin-right: 0; margin-bottom: 16px;}
// }
/* Facebook Button Styles */
.facebook-button {
  border-radius: 3px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.04);
  height: 48px;
  width: 100%;
  text-align: left;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 13px !important;
  cursor: pointer;
  background: #fff;
  font-family: Graphik;
}
.facebook-button i {
  font-size: 24px;
  color: #39559f;
  margin-right: 12px;
}
.facebook-button:hover {
  box-shadow: none !important;
  transition: box-shadow 300ms linear;
}
@media only screen and (max-width: 624px) {
  .facebook-button {
    font-size: 13px !important;
  }
}
/* Login Options Text Styles */
.login-options-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  background: #fff;
  margin-top: -48px;
}
@media only screen and (max-width: 600px) {
  .login-options-text {
    margin-top: -32px;
  }
}
/* Account Form Styles */
.account-form {
  max-width: 416px;
  margin: 0 auto;
}
.account-form.register-form {
  max-width: 548px;
}
.account-form .input-container {
  margin-bottom: 24px;
}
.account-form .register-form-grid {
  @extend .profile-inputs;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
.account-form .register-form-grid .input-container .error-text {
  bottom: unset;
  top: 48px;
}
.account-form .register-form-grid .phone-input {
  border: none;
}
@media only screen and (max-width: 600px) {
  .account-form .register-form-grid {
    display: unset;
  }
}
