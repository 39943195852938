/***** CURSOR STYLES *****/
.pointer {
  cursor: pointer;
}

/***** LINE HEIGHT STYLES *****/
.line-height-double {
  line-height: 1.5;
}

/***** GRADIENT IMG OVERLAY STYLES *****/
// .gradient-img-container {
//   background: rgb(240,98,98);
//   background: linear-gradient(-45deg, rgba(240,98,98,1) 0%, rgba(19,38,243,1) 100%);
// }
// .gradient-img-container img {mix-blend-mode: hard-light;}
