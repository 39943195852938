.card {
  background: #fff;
  border-radius: 3px;
  box-shadow:
    0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 6px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding: 32px;
}
.card.flat-card {
  box-shadow: none;
}
