.tooltip-container {
  position: relative;
}
.tooltip {
  position: absolute;
  left: -24px;
  background-color: #666666;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 11px;
  color: #fff;
  white-space: nowrap;
  display: inline-block;
  padding: 4px 8px;
  bottom: 100%;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 600px) {
  .tooltip {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 400px) {
  .tooltip {
    right: -8px;
    font-size: 10px;
  }
}
