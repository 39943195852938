/***** CONTACT PAGE HEADER STYLES *****/
.contact-page-title {
  font-size: 40px;
  margin-top: 64px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1040px) {
  .contact-page-title {
    font-size: 32px;
    line-height: 35px;
  }
  .contact-page-subtitle {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .contact-page-title {
    font-size: 24px;
    margin-top: 16px;
  }
}

/***** CONTACT METHODS STYLES ****/
.contact-methods {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 920px;
  margin: 64px auto;
  align-items: flex-start;
}
@media only screen and (max-width: 600px) {
  .contact-methods {
    margin: 40px auto;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}
