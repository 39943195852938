/***** CREDITS (LOGGED OUT) STYLES *****/
.credits-header {
  background: url("/images/credits-background.jpg");
  height: 400px;
  padding: 104px 24px;
  margin-bottom: 104px;
  color: #fff;
}
.credits-section-accent-background {
  background: #f7f7fa;
  padding: 80px 24px;
}
.credits-section-title {
  font-size: 32px;
}
.reward-description {
  max-width: 196px;
  height: 64px;
}
.discount-section {
  height: 64px;
  color: #fff;
  background-color: $accent-color-secondary;
}
.credit-expiration-container {
  border: 1px solid $accent-color-secondary;
  border-left: 5px solid $accent-color-secondary;
  padding: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.expiration-warning-icon {
  font-size: 32px;
  margin-left: 5px;
  color: $accent-color-secondary;
}
.expiration-info-modal {
  width: 100%;
  max-width: 500px;
}
.expiration-info-icon {
  margin-top: 15px;
  margin-left: -12%;
  color: $accent-color-secondary;
  font-size: 16px;
}
.expiration-info-text {
  line-height: 1.4;
}
.expiration-text {
  line-height: 1.4;
}
.large-expiration-text {
  font-size: 16px;
}
.med-expiration-text {
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .credits-header {
    padding: 40px 16px;
    margin-bottom: 32px;
    height: auto;
  }
  .credits-section-title {
    font-size: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .reward-description {
    max-width: 320px;
    margin: 16px auto 0;
    height: auto;
  }
}

/***** CREDITS (LOGGED IN) STYLES *****/
.my-credits-page {
  padding: 40px 0;
}
@media only screen and (max-width: 600px) {
  .my-credits-page {
    padding: 24px 0;
  }
  // .credits-section.mb-80 {margin-bottom: 40px;}
}
/*** Overview Card ***/
.credits-overview-card {
  padding: 40px 48px 40px 0;
  display: flex;
}
.credits-overview-card .total-credits {
  border-right: 1px solid #ebecf2;
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.credits-overview-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  grid-gap: 16px;
  justify-items: center;
}
.credits-overview-grid.hide-months {
  grid-template-columns: repeat(3, 1fr);
}
@media only screen and (max-width: 1040px) {
  .credits-overview-card {
    padding: 24px 40px 24px 0;
  }
  .credits-overview-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-items: flex-start;
    padding-left: 40px;
  }
  .credits-overview-grid img {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .credits-overview-card {
    height: auto;
    display: block;
    padding: 16px 24px 24px 24px;
  }
  .credits-overview-card .total-credits {
    border-right: none;
    border-bottom: 1px solid #ebecf2;
    width: calc(100% + 48px);
    margin: 0 -24px 24px;
    padding: 0 24px 16px;
  }
  .credits-overview-grid,
  .credits-overview-grid.hide-months {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 24px;
  }
  .credits-overview-grid img {
    margin-right: 16px;
  }
  .credits-overview-grid p.mt-24 {
    margin-top: 0;
  }
}
/*** Overview Cards Styles ***/
.credits-overview-cards {
  justify-content: center;
  display: grid;
  grid-template-columns: calc(43% - 53px) 36px calc(43% - 53px);
  align-items: center;
  grid-gap: 16px;
}
.credits-overview-cards .card {
  width: 420px;
  max-width: 100%;
  height: 100%;
  padding: 16px;
}
@media only screen and (max-width: 600px) {
  .credits-overview-cards {
    grid-template-columns: 1fr;
  }
  .credits-overview-cards .card {
    width: 100%;
  }
}
/* Uncomplete Course Styles */
.certificate-course-status-circle {
  height: 24px;
  width: 24px;
  border: 2px solid #dcdde8;
  background: #fff;
  display: block;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto 16px;
  z-index: 1;
  position: relative;
}
.certificate-course-number:after {
  content: "";
  border: 1px dashed #dcdde8;
  width: calc(100% + 56px);
  position: absolute;
  top: 12px;
  left: 24px;
}
.certificate-course-number:nth-last-child(2):after {
  width: calc(100% + 80px);
}
/* Completed Course Styles */
.certificate-course-number.completed .certificate-course-status-circle {
  border-color: #1326f3;
  background: #1326f3;
}
.certificate-course-status-circle.completed {
  border-color: #1326f3;
  background: #1326f3;
}
.certificate-course-number.completed:after {
  border: 1px solid #1326f3;
}
/* File Preview Styles */
.file-preview-modal {
  width: 800px;
  height: 80vh;
}
.file-preview-modal object {
  width: 100%;
  height: calc(100% - 56px);
}
.file-preview-modal.download-modal {
  width: 400px;
  height: 160px;
}
/* Tablet Styles */
@media only screen and (max-width: 1040px) {
  .certificate-course-number:after {
    width: calc(100% + 24px);
  }
  .certificate-course-number:nth-last-child(2):after {
    width: calc(100% + 40px);
  }
  .certificate-course-number.medium-text {
    font-size: 14px;
  }
}
/* Mobile Styles */
@media only screen and (max-width: 600px) {
  .mobile-course-number-container {
    border-radius: 50%;
    width: 72px;
    height: 72px;
    display: flex;
    background: #ddd;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
  }
  .mobile-course-number-container .mobile-course-number {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: auto;
    background: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .progress-1 {
    background-image: linear-gradient(-90deg, #ddd 50%, transparent 50%),
      linear-gradient(-45deg, #028cd5 50%, #ddd 50%);
  }
  .progress-2 {
    background-image: linear-gradient(-90deg, #ddd 50%, transparent 50%),
      linear-gradient(0deg, #028cd5 50%, #ddd 50%);
  }
  .progress-3 {
    background-image: linear-gradient(-90deg, #ddd 50%, transparent 50%),
      linear-gradient(45deg, #028cd5 50%, #ddd 50%);
  }
  .progress-4 {
    background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
      linear-gradient(-270deg, #028cd5 50%, #ddd 50%);
  }
  .progress-5 {
    background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
      linear-gradient(-225deg, #028cd5 50%, #ddd 50%);
  }
  .progress-6 {
    background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
      linear-gradient(-180deg, #028cd5 50%, #ddd 50%);
  }
  .progress-7 {
    background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
      linear-gradient(-135deg, #028cd5 50%, #ddd 50%);
  }
  .progress-8 {
    background-image: linear-gradient(90deg, #028cd5 50%, transparent 50%),
      linear-gradient(-90deg, #028cd5 50%, #ddd 50%);
  }
}
/*** Rewards Styles ***/
/* Trip Card Styles */
.my-credits-page .two-column-grid-section-right {
  padding: 40px;
}
.my-credits-page .two-column-grid-section-right-content {
  margin-left: 74px;
  max-width: 600px;
}
.my-credits-page .two-column-grid-section-right-content .section-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
}
@media only screen and (max-width: 1040px) {
  .my-credits-page .two-column-grid-section-right-content {
    margin-left: 44px;
  }
  .my-credits-page .two-column-grid-section-right-content .section-title {
    font-size: 24px;
    max-width: 320px;
    margin: 0 auto 16px;
  }
  .my-credits-page
    .two-column-grid-section-right-content
    .section-title
    + .large-text {
    font-size: 16px;
  }
  .my-credits-page .two-column-grid-section {
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 600px) {
  .my-credits-page .two-column-grid-section.mb-80 {
    margin-bottom: 64px;
  }
  .my-credits-page .two-column-grid-section-left img {
    object-position: top;
  }
  .my-credits-page .two-column-grid-section-right {
    height: auto;
  }
  .my-credits-page .two-column-grid-section-right-content {
    left: 0;
    margin-left: 0;
    max-width: unset;
  }
  .my-credits-page
    .two-column-grid-section-right-content
    .section-title
    + .large-text {
    font-size: 14px;
  }
  .my-credits-page .two-column-grid-section {
    grid-template-columns: none;
  }
}
/* Reward Card Styles */
.rewards-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 280px));
  grid-gap: 24px;
}
.reward-card.card {
  padding: 16px;
  position: relative;
  border-top: 3px solid $accent-text;
}
@media only screen and (max-width: 600px) {
  .rewards-cards-grid {
    grid-template-columns: 1fr;
  }
}
/* Reward Modal Styles */
.reward-modal.card {
  width: 100%;
  max-width: 480px;
  border-top: 4px solid;
  padding: 24px;
}
// .reward-modal .custom-select {position: absolute; z-index: 1;}
.reward-modal .custom-select .input-container-select__menu-list {
  max-height: 90px;
}
.reward-redeemed-modal.card {
  width: 100%;
  max-width: 480px;
  padding: 40px;
}
/* Program Reward Styles */
.programs-reward.reward-card,
.programs-reward.reward-modal {
  border-top-color: #1326f3;
}
.programs-reward .reward-card-footer,
.programs-reward .reward-modal-footer {
  color: #1326f3;
}
.programs-reward .reward-modal-footer .btn {
  background-color: #1326f3;
  color: #fff;
  border: 1px solid #1326f3;
}
/* Charity Reward Styles */
.charity-reward.reward-card,
.charity-reward.reward-modal {
  border-top-color: $accent-color-secondary;
}
.charity-reward .reward-card-footer,
.charity-reward .reward-modal-footer {
  color: $accent-color-secondary;
}
.charity-reward .reward-modal-footer .btn {
  background-color: $accent-color-secondary;
  color: #fff;
  border: 1px solid $accent-color-secondary;
}
/* Cash Reward Styles */
.cash-reward.reward-card,
.cash-reward.reward-modal {
  border-top-color: #63c674;
}
.cash-reward .reward-card-footer,
.cash-reward .reward-modal-footer {
  color: #63c674;
}
.cash-reward .reward-modal-footer .btn {
  background-color: #63c674;
  color: #fff;
  border: 1px solid #63c674;
}
/* Trip Reward Styles */
.trip-reward.reward-modal {
  border-top-color: #1326f3;
}
.reward-modal-trip-disclaimer {
  margin-top: 16px;
  line-height: 1.5;

  ul {
    margin-top: 8px;
    list-style: disc inside;
  }
}

.trip-reward .reward-modal-footer {
  color: #1326f3;
}
.trip-reward .reward-modal-footer .btn {
  background-color: #1326f3;
  color: #fff;
  border: 1px solid #1326f3;
}
.trip-rewards-divider {
  border-bottom: 1.4px solid #ebecf2;
  width: 120px;
}
