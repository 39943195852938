/***** GRAND TRIP STYLES *****/
.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
}
.background-video {
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 360px;
}
.video-container .header-text {
  position: relative;
  z-index: 1; /* Ensure the text appears above the video */
  color: #ffffff;
  font-family: Graphik;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
  opacity: 0.6;
}
.cards-container {
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  place-content: center;
}
.cards-container .card {
  color: #ffffff;
  font-family: Graphik;
  text-align: center;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 286px;
  width: 100%;
  max-width: 526px;
}
.cards-container .card:not(:last-child) {
  margin-right: 60px;
}
.card-title {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 35px;
}
.card-text {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  max-width: 343px;
}
.my-credits-page {
  padding: 0;
}
.top-section-header-container {
  font-family: Graphik;
  background-color: #f7f7fa;
  text-align: center;
  padding: 80px 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);

  .title {
    margin-bottom: 24px;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: bold;
  }
  .sub-title {
    margin-bottom: 60px;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
    max-width: 645px;
  }
}
.trip-rewards-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: Graphik;
}
.trip-rewards-header {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
  margin: 80px auto 60px;
}
.trip-reward-info {
  display: flex;
  flex: 0 0 100%;
  min-height: 394px;
  position: relative;
}
.trip-reward-info:nth-child(even) {
  flex-direction: row-reverse;
}
.trip-reward-info > img {
  flex: 0 0 50%;
  max-width: 50%;
  object-fit: cover;
  background-color: #dcdde8;
}
.trip-reward-info .details {
  flex: 0 0 50%;
  background-color: #f06262;
  color: #ffffff;
  padding: 40px 26px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.trip-reward-info .details button {
  width: 148px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding: 0;
}
.trip-reward-type {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 6px;
}
.trip-reward-title {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 8px;
}
.trip-reward-dates {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 16px;
}
.trip-reward-registration-dates {
  @extend .trip-reward-dates;
  font-size: 16px;
}
.trip-reward-description {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 12px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.trip-reward-credit {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.company-logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
}
.company-logo img {
  width: 61px;
  height: 61px;
}

@media only screen and (max-width: 600px) {
  .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 168px;
  }
  .background-video {
    height: 168px;
  }
  .video-container .header-text {
    font-size: 24px;
    line-height: 26px;
    margin: auto 16px;
  }
  .top-section-header-container {
    background-color: #f7f7fa;
    padding: 32px 16px;

    .title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .sub-title {
      font-size: 16px;
      line-height: 24px;
      max-width: 275px;
      margin-bottom: 24px;
    }
  }
  .cards-container {
    flex-direction: column;
    margin-bottom: 24px;
    width: 100%;
  }
  .cards-container .card {
    width: 100%;
    min-height: 274px;
  }
  .cards-container .card:not(:last-child) {
    margin-bottom: 16px;
    margin-right: 0px;
  }
}
.trip-details-header {
  font-size: 32px;
  color: #ffffff;
  height: 360px;
  padding: 104px 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.trip-icon-img {
  color: #f06262;
  width: 20px;
  height: 19px;
}
.trip-itinerary-background {
  background-image: url("/images/trip-itinerary-background.svg");
}
.trip-itinerary-grid {
  min-height: 300px;
}
.trip-itinerary-container {
  flex: 0 0 50%;
  padding: 40px 26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
}
.trip-itinerary {
  display: flex;
  height: 114px;
  align-content: space-between;
}
.trip-itinerary .day-info {
  border: 1px solid #f06262;
  width: 114px;
  height: 114px;
  margin-right: 30px;
}
.trip-itinerary .day {
  background: #f06262;
  align-content: center;
  display: grid;
  border: 1px solid #f06262;
  color: #fff;
  font-weight: 500;
  height: 36px;
  font-size: 16px;
}
.trip-itinerary .number {
  font-weight: 700;
  color: #f06262;
  font-size: 40px;
  height: 78px;
  align-content: center;
  display: grid;
}
.trip-itinerary-title {
  height: fit-content;
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.trip-itinerary-description {
  text-align: left;
  width: 532px;
  height: 78px;
  line-height: 26px;
  overflow: auto;
}
.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-inline: 10%;
}

.gallery-image img {
  border-radius: 2px;
  height: 300px;
  object-fit: cover;
}

.gallery-image:nth-child(1) img {
  width: 300px;
}
.gallery-image:nth-child(2) img {
  width: 400px;
}
.gallery-image:nth-child(3) img {
  width: 300px;
}
.gallery-image:nth-child(4) img {
  width: 350px;
}
.gallery-image:nth-child(5) img {
  width: 300px;
}
.gallery-image:nth-child(6) img {
  width: 350px;
}

@media only screen and (max-width: 1040px) {
  .cards-container .card {
    margin: 0px;
  }
  .cards-container .card:not(:last-child) {
    margin-right: 30px;
  }
  .card-title {
    margin-bottom: 16px;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
  }
  .card-text {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
  }
  .trip-rewards-container {
    width: auto;
    margin: auto 16px;
  }
  .trip-rewards-header {
    font-size: 20px;
    line-height: 24px;
    margin: 32px auto 24px;
  }
  .trip-reward-info {
    height: max-content;
    min-height: max-content;
    flex-direction: column;
  }
  .trip-reward-info:nth-child(even) {
    flex-direction: column;
  }
  .trip-reward-info > img {
    flex: 0 0 100%;
    min-height: 140px;
    max-height: 140px;
    max-width: 100%;
    object-fit: cover;
  }
  .trip-reward-info .details {
    flex: 0 0 100%;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ebecf2;
    align-items: flex-start;
    padding: 18px 16px 24px;
    text-align: left;
    margin-bottom: 24px;
    justify-content: flex-start;
  }
  .trip-reward-info .details button {
    width: 120px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-align: center;
  }
  .trip-reward-type {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }
  .trip-reward-dates {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 12px;
    margin-right: 0;
  }
  .trip-reward-title {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 12px;
  }
  .trip-reward-description {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .trip-reward-credit {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }
  .gallery-grid {
    display: block;
  }
  .gallery-image {
    margin-right: 0;
  }
  .gallery-image:nth-child(n) img {
    width: 100%;
    margin-right: 0;
  }
  .trip-itinerary-container {
    margin-left: 5%;
    margin-right: 5%;
    display: block;
    padding: 30px 26px;
  }
  .trip-itinerary {
    height: 78px;
  }
  .trip-itinerary .day-info {
    width: 78px;
    height: 78px;
  }
  .trip-itinerary .day {
    height: 26px;
  }
  .trip-itinerary .number {
    height: 54px;
    width: 78px;
  }
  .trip-itinerary-title {
    font-size: 20px;
    height: fit-content;
  }
  .trip-itinerary-description {
    width: 100%;
    height: fit-content;
    margin-top: 10px;
  }
}
