/***** 404 Page Styles *****/
.page-not-found {
  background: linear-gradient(135deg, #007ab5 0%, #0c425d 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*** Background Styles ***/
.page-not-found-bg {
  background-image: url("/images/404-bg.png");
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-position: center;
  pointer-events: none;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 480px) {
  .page-not-found-bg {
    background-size: 175%;
  }
}
/* Background Circle Styles */
.page-not-found-circles {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.circle1 {
  height: 800px;
  width: 800px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -400px;
  margin-left: -400px;
}
.circle2 {
  height: 550px;
  width: 550px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
}
@media only screen and (max-width: 720px) {
  .page-not-found-circles {
    display: none;
  }
}
/*** Rocket Text Styles ***/
.rocket-text {
  display: flex;
  align-items: center;
}
.rocket-text p {
  font-weight: 700;
  color: #fff;
  font-size: 140px;
}
.rocket-text p:first-child {
  margin-right: -32px;
}
.rocket-text p:last-child {
  margin-left: -40px;
}
@media only screen and (max-width: 600px) {
  .rocket-text img {
    height: 136px;
    z-index: 1;
  }
}
/*** Home Btn Styles ***/
a.home-btn {
  background: #fff;
  color: #0c425d;
}
.home-btn:hover {
  background: #0c425d;
  color: #fff;
}
