/***** ABOUT HEADER STYLES *****/
.about-page-title {
  font-size: 40px;
  margin-bottom: 40px;
  margin-top: 104px;
}
.about-page-subtitle {
  max-width: 840px;
  margin: 0 auto 104px;
  font-size: 20px;
}
@media only screen and (max-width: 1040px) {
  .about-page-title {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .about-page-subtitle {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .about-page-title {
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 40px;
  }
  .about-page-subtitle {
    margin: 0 auto 40px;
  }
}

/***** ABOUT JEWISH U STYLES *****/
.about-jewish-u {
  max-width: 820px;
  margin: 0 auto 104px;
}
.about-jewish-u img {
  height: 180px;
  min-width: 180px;
  width: 180px;
  object-fit: cover;
  margin-right: 40px;
}
@media only screen and (max-width: 1040px) {
  .about-jewish-u .large-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .about-jewish-u {
    margin: 0 auto 80px;
  }
  .about-jewish-u img {
    height: 96px;
    min-width: 96px;
    width: 96px;
    margin-bottom: 24px;
  }
}

/***** FAQ STYLES *****/
.faqs {
  background: #f7f7fa;
  padding: 80px;
}
.faq-title {
  font-size: 32px;
  text-align: center;
}
.faqs .container {
  max-width: 800px;
  padding: 0;
}
.faq:not(:last-child) {
  margin-bottom: 24px;
}
@media only screen and (max-width: 1040px) {
  .faqs {
    padding: 80px 16px;
  }
  .faq-title {
    font-size: 24px;
  }
  .faqs .large-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .faqs {
    padding: 40px 16px;
  }
}
