/***** BANNER STYLES *****/
.banner-wrapper {
  position: fixed;
  width: 100%;
  z-index: 2100;
}
.banner {
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  background-color: $accent-color;
  color: #fff;
  font-family: Graphik;
  position: sticky;
  top: 0px;
}
.banner-wrapper + .header {
  top: 48px;
}
.banner-wrapper + .header + .page-wrapper {
  padding-top: 120px;
}

.banner a:hover {
  border: 1px solid #fff;
}
@media only screen and (max-width: 1040px) {
  .banner-wrapper + .header + .page-wrapper {
    padding-top: 104px;
  }
}
@media only screen and (max-width: 560px) {
  .banner-wrapper {
    z-index: 210;
  }
  .banner {
    justify-content: space-between;
  }
}
