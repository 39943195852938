/***** HEADER STYLES *****/
.header {
  position: sticky;
  top: 0;
  background: #fff;
  height: 72px;
  z-index: 100;
  border-bottom: 1px solid #ebecf2;
}
.header .container,
.header .container .nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header .container a img {
  height: 32px;
}
@media only screen and (max-width: 1040px) {
  .header {
    height: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .header {
    z-index: 200;
  }
  .header .container {
    grid-template-columns: repeat(2, auto);
  }
}

/*** BH Styles ***/
@media only screen and (max-width: 648px) {
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
/*** Account Dropdown Styles ***/
.account-dropdown {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
/* Account Dropdown Header Styles */
.account-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.account-dropdown-header .user-profile-pic {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100%;
}
.account-dropdown-header .user-name {
  margin-left: 8px;
}
.account-dropdown-header .user-name i {
  vertical-align: middle;
  margin-top: -3px;
}
/* Account Dropdown Box Styles */
.dropdown-box {
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  box-shadow:
    0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  transition: opacity 300ms linear;
  opacity: 0;
  visibility: hidden;
}
.dropdown-box.open {
  opacity: 1;
  visibility: visible;
}
.account-dropdown-box {
  width: 220px;
  right: 0;
  top: 32px;
}
.account-dropdown-box-header {
  border-bottom: 1px solid #edecec;
  padding-bottom: 12px;
  margin-bottom: 16px;
  display: flex;
}
.account-dropdown-box-header img {
  margin-right: 16px;
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100%;
}
.account-dropdown-box-header .user-name {
  margin: 2px 0 6px 0;
}
.account-dropdown-box-body p {
  transition: color 300ms linear;
}
.account-dropdown-box-body p:last-child:hover {
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .mobile-menu {
    border-bottom: 1px solid #ebecf2;
    margin-bottom: 16px;
  }
  .mobile-menu a {
    display: block;
    margin-bottom: 16px;
  }
  .mobile-menu a.mb-24 {
    margin-bottom: 24px;
  }
}

// TODO - these are from shluchim portal for menu!
/*** Nav Styles ***/
nav {
  height: 100%;
  justify-self: center;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.nav-link > a {
  display: flex;
  align-items: center;
  margin: 0 16px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  transition: opacity 300ms linear;
}
@media only screen and (max-width: 720px) {
  .nav-link > a {
    margin: 0 8px;
  }
}
.nav-link.no-hover > a {
  pointer-events: none;
}
.nav-link:not(.no-hover) a:not(.active-nav-link):hover {
  opacity: 0.5;
}
a.active-nav-link {
  color: $accent-color;
  cursor: default;
}
a.active-nav-link:hover,
a.active-nav-link.link-text-secondary:hover {
  opacity: 1;
  cursor: default;
}
a.active-nav-link.refresh {
  cursor: pointer;
}
.program-dropdown-container:hover .nav-menu-dropdown-box {
  opacity: 1;
  visibility: visible;
}
.nav-menu-dropdown-box {
  width: 320px;
  top: 56px;
}
.nav-menu-dropdown-box a {
  font-size: 14px;
  display: block;
  margin-bottom: 16px;
}
.nav-menu-dropdown-box a:last-child {
  margin-bottom: 0;
  border-top: 1px solid #edecec;
  padding-top: 16px;
  display: block;
}
.nav-menu-dropdown-box a.link-text.active-nav-link:hover {
  opacity: 1;
}
@media only screen and (max-width: 720px) {
  .nav-menu-dropdown-box {
    width: 264px;
  }
  .nav-link > a {
    margin: 0 8px;
  }
}
