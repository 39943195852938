.search-input {
  border-radius: 3px;
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  max-width: 100%;
  transition: border 300ms linear;
  margin: 40px auto 16px auto;
  position: relative;
  background-color: #fff;
}
.search-input > .flex > div {
  width: 100%;
}
.search-input-focused {
  border: 1px solid $accent-color;
}
.search-input input {
  background: transparent;
  border: none;
  font-size: 14px;
  width: calc(100% - 16px);
}
.search-input input::placeholder {
  color: $accent-text-dark;
}
.search-input input:focus {
  outline: none;
  border: none;
}
.autocomplete-menu {
  position: absolute;
  z-index: 3;
  margin-top: 12px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 1px 2px rgba(0, 0, 0, 0.08);
  top: 28px !important;
  left: 44px !important;
  max-height: 144px;
  overflow: auto;
}
.autocomplete-menu > div {
  transition: background 300ms linear;
}
.search-input .btn-dark {
  height: 100%;
  font-family: Fixture;
  font-weight: 600;
  margin-right: -16px;
  width: 80px;
  border-radius: 0 3px 3px 0;
  border: none;
  border-left: 1px solid $accent-color;
}
.search-input .btn-dark:hover {
  border-color: $accent-color;
}

@media only screen and (max-width: 1040px) {
  .search-input {
    width: 340px;
  }
}

@media only screen and (max-width: 620px) {
  .search-input {
    width: 280px;
  }
}

@media only screen and (max-width: 600px) {
  .search-input {
    width: 100%;
    margin-bottom: 12px;
    padding-left: 8px;
    margin-top: 24px;
  }
  .page-wrapper-mobile .search-input input {
    font-size: 16px;
  }
  .search-input input::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
}
