@font-face {
  font-family: Graphik;
  src: url(/fonts/GRAPHIK-REGULAR.OTF);
  font-weight: 400;
}
@font-face {
  font-family: Graphik;
  src: url(/fonts/GRAPHIK-BOLD.OTF);
  font-weight: 700;
}
@font-face {
  font-family: Graphik;
  src: url(/fonts/GRAPHIK-MEDIUM.OTF);
  font-weight: 500;
}
@font-face {
  font-family: Graphik;
  src: url(/fonts/GRAPHIK-SEMIBOLD.OTF);
  font-weight: 600;
}

body {
  font-family: Graphik;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
a {
  text-decoration: none;
  color: inherit;
}

/***** FONT WEIGHT STYLES *****/
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}

/***** FONT SIZE STYLES *****/
.xs-text {
  font-size: 10px;
}
.small-text {
  font-size: 12px;
}
.medium-text {
  font-size: 16px;
}
.large-text {
  font-size: 18px;
}
.xl-text {
  font-size: 20px;
}
.xxl-text {
  font-size: 24px;
}
.xxxl-text {
  font-size: 40px;
}
@media only screen and (max-width: 600px) {
  .xxxl-text {
    font-size: 24px;
  }
  i.xxxl-text {
    font-size: 40px;
  }
  .xxl-text {
    font-size: 20px;
  }
  .xl-text {
    font-size: 18px;
  }
  .large-text {
    font-size: 16px;
  }
}

/***** FONT STYLES *****/
.link-text {
  color: $accent-color;
  transition: opacity 300ms linear;
  cursor: pointer;
  text-decoration: none;
}
.link-text.material-icons {
  font-family: "Material Icons";
}
.link-text:hover {
  opacity: 0.5;
}
.link-text.no-hover:hover {
  opacity: 1;
}
.link-text-heavy {
  font-family: AvenirHeavy;
}
.link-text .back-arrow {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 8px;
}
.link-with-icon i {
  color: $accent-text-light;
  transition: color 300ms linear;
}
.link-with-icon:hover i {
  color: $accent-color-dark;
}
.page-wrapper-mobile .link-with-icon i {
  color: $accent-color-dark;
}
.link-text-secondary {
  transition: opacity 300ms linear;
}
.link-text-secondary:hover {
  opacity: 0.6;
  cursor: pointer;
}
.strike-through {
  text-decoration: line-through;
}

/***** TEXT COLOR STYLES ****/
.accent-text-light {
  color: $accent-text-light;
}
.accent-text {
  color: $accent-text;
}
.accent-text-dark {
  color: $accent-text-dark;
}
.accent-text-secondary {
  color: $accent-color;
}
.accent-text-tertiary {
  color: #f06262;
}
.accent-text-light-secondary {
  color: $accent-color-secondary;
}
.error-text {
  color: $error-color;
}

/***** TEXT TRANSFORM STYLES *****/
.uppercase-text {
  text-transform: uppercase;
}

/***** TEXT ALIGN STYLES *****/
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
@media only screen and (max-width: 1040px) {
  .tablet-text-right {
    text-align: right;
  }
}

/***** TEXT DECORATION STYLES *****/
.underline-text {
  text-decoration: underline;
}

/***** FONT STYLE STYLES *****/
.italic-text {
  font-style: italic;
}
