/***** ALL LOCATIONS STYLES *****/
/*** Locations Header Styles ***/
.locations-header {
  height: 264px;
  position: relative;
  color: #fff;
  margin: 40px 0px;
}
.locations-header .locations-title {
  font-size: 48px;
  font-weight: 700;
}
.locations-header img {
  margin: -40px;
  width: calc(100% + 40px);
  height: 344px;
  object-fit: cover;
  position: absolute;
  top: -40px;
  overflow: hidden;
}
/* Locations Search Page */
.locations-search-page .locations-header {
  height: 600px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locations-search-page .locations-header img {
  height: 100%;
  width: 100%;
  margin: 0;
  top: 0;
}
.locations-header > div {
  position: relative;
  text-align: center;
  padding: 32px;
}
.locations-search-page .search-input.mb-40 {
  margin-bottom: 24px;
}
.locations-search-page .search-input .btn-accent {
  height: calc(100% + 2px);
  margin-right: -18px;
  width: 80px;
  border-radius: 0 3px 3px 0;
  border: none;
  border-left: 1px solid #1326f3;
}
.locations-search-page .input-container-select__indicator-separator,
.locations-search-page .input-container-select__dropdown-indicator {
  display: none;
}
.locations-search-page .input-container-select__clear-indicator {
  color: #1326f3;
}
.locations-search-page .input-container-select__placeholder,
.locations-search-page .input-container-select__single-value {
  color: $accent-color-dark;
  margin-top: 2px;
  height: 16px;
}
.locations-search-page .input-container-select__clear-indicator:hover {
  opacity: 0.5;
  color: #1326f3;
}
.locations-search-page .input-container-select__menu {
  color: $accent-color-dark;
  text-align: left;
}
.locations-search-page .input-container-select__control {
  background-color: transparent;
  border: none;
}
@media only screen and (max-width: 1040px) {
  .locations-header .locations-title {
    font-size: 32px;
  }
  .locations-search-page .locations-header {
    height: 436px;
    min-height: calc(100vh - 412px);
  }
  .locations-search-page .locations-header .xl-text.mt-24.mb-40 {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 600px) {
  .locations-header {
    font-size: 24px;
    height: auto;
    margin: 0 0 24px 0;
  }
  .locations-header > div {
    padding: 48px 16px;
  }
  .locations-header .locations-title {
    font-size: 24px;
    line-height: 1.25;
    max-width: 288px;
    margin: 0 auto 24px;
  }
  .locations-header img {
    height: 100%;
    top: 0;
    margin: 0;
    width: 100%;
  }
  .locations-search-page .locations-header {
    margin: 0;
    min-height: unset;
  }
  .locations-search-page .locations-header img {
    height: 100%;
  }
}
/*** Locations Cards Styles ***/
.locations-cards-grid {
  @extend .all-courses-cards-grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
}
.locations-cards-grid-loader {
  height: 80px;
  margin-top: 80px;
}
.location-card.card {
  padding: 16px;
  display: flex;
  height: 128px;
}
.location-card img {
  margin: -16px;
  width: 160px;
  min-width: 160px;
  height: 126px;
  object-fit: cover;
  margin-right: 24px;
  padding-bottom: 0px;
  border-radius: 3px 0 0 3px;
}
@media only screen and (max-width: 1040px) {
  .locations-cards-grid-container {
    padding: 0 80px 80px 80px;
  }
  .locations-cards-grid {
    grid-template-columns: 1fr;
  }
  .location-card .my-location-tag {
    position: absolute;
    bottom: 0;
  }
}
@media only screen and (max-width: 600px) {
  .locations-cards-grid-container {
    padding: 0;
  }
  .locations-cards-grid {
    grid-template-columns: 1fr;
  }
  .location-card.card {
    box-shadow: none;
    border-bottom: 1px solid #dcdde8;
    border-top: none;
    min-height: 104px;
    height: auto;
    padding: 0 16px 16px 0;
  }
  .location-card img {
    margin: 0 16px 0 0;
    width: 104px;
    min-width: 104px;
    height: 88px;
    border-radius: 0;
  }
  .location-card .my-location-tag {
    position: relative;
    margin-top: 8px;
  }
}

/***** LOCATION DETAILS STYLES *****/
/*** Header Styles ***/
.location-details-header {
  height: 360px;
  margin: 0 0 40px;
  position: relative;
  padding: 24px 40px 64px;
  display: flex;
  align-items: center;
}
.location-details-header-img-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.location-details-header-img-container img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.location-details-header-img-container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}
.location-details-header p {
  color: #fff;
  position: relative;
  z-index: 1;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  text-align: center;
}
@media only screen and (max-width: 1040px) {
  .location-details-header {
    padding: 40px 0;
    margin: 0 0 40px 0;
    height: 260px;
  }
  .location-details-header p {
    font-size: 32px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .location-details-header {
    padding: 16px 0;
    margin: 0 0 24px 0;
    height: 160px;
  }
  .location-details-header p {
    font-size: 24px;
    line-height: 32px;
  }
}
/*** Sidebar Styles ***/
.location-details-sidebar {
  width: 200px;
  min-width: 200px;
  margin-right: 80px;
}
.location-details-content {
  max-width: calc(100% - 280px);
}
.my-location-tag {
  display: flex;
  align-items: center;
  color: #63c674;
  font-weight: 500;
  margin-top: 20px;
}
@media only screen and (max-width: 1040px) {
  .location-details-sidebar {
    margin-right: 40px;
  }
  .location-details-content {
    max-width: calc(100% - 240px);
  }
}
@media only screen and (max-width: 600px) {
  .location-details-sidebar {
    width: 100%;
  }
  .location-details-content {
    max-width: 100%;
  }
}
/*** Divider Styles ***/
.mobile-locations-details-divider {
  border-bottom: 1px solid #ebecf2;
  margin: 16px -16px 24px;
}
/*** Section Title Styles ***/
.location-details-section-title {
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 700;
}
@media only screen and (max-width: 1040px) {
  .location-details-section-title {
    font-size: 20px;
  }
}
/*** Available Courses Styles ***/
.course-category-select {
  @extend .course-schedule-select;
  margin-top: 0px;
  max-width: 100%;
}
.location-details-section .custom-checkbox[type="checkbox"] + label {
  white-space: nowrap;
}
.location-details-section
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
.location-courses-cards-grid {
  @extend .all-courses-cards-grid;
  grid-template-columns: repeat(auto-fit, minmax(0, calc(33.3% - 12px)));
}
.location-courses-cards-grid.single-card-grid .course-card {
  max-width: 360px;
}
@media only screen and (max-width: 1040px) {
  .location-courses-cards-grid {
    grid-template-columns: repeat(auto-fit, minmax(0, calc(50% - 12px)));
  }
}
@media only screen and (max-width: 760px) {
  .location-courses-cards-grid {
    grid-template-columns: 1fr;
  }
}
