/***** LOADER STYLES *****/
.loader-container {
  $color: #1326f3;
  $duration: 3s;
  $width: 4px;
  position: relative;
  height: 100%;

  .loader {
    position: absolute;
    width: 3vw;
    min-width: 30px;
    height: 3vw;
    min-height: 30px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    box-shadow: inset 0px 0px 0px $width rgba($color, 0.3);

    animation: load-rotate $duration * 4 infinite;

    @keyframes load-rotate {
      24.9% {
        transform: translateY(-50%) translateX(-50%) rotate(0deg);
      }
      25% {
        transform: translateY(-50%) translateX(-50%) rotate(90deg);
      }
      49.9% {
        transform: translateY(-50%) translateX(-50%) rotate(90deg);
      }
      50% {
        transform: translateY(-50%) translateX(-50%) rotate(180deg);
      }
      74.999% {
        transform: translateY(-50%) translateX(-50%) rotate(180deg);
      }
      75% {
        transform: translateY(-50%) translateX(-50%) rotate(270deg);
      }
      100% {
        transform: translateY(-50%) translateX(-50%) rotate(270deg);
      }
    }
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background: $color;
    }

    &:before {
      transform: scaleX(0);
    }

    &:after {
      transform: scaleY(0);
    }
  }

  .one {
    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: $width;
      animation: one $duration forwards infinite;

      @keyframes one {
        0% {
          transform: scaleX(0);
          transform-origin: left;
        }
        12.5% {
          transform: scaleX(1);
        }
        37.5% {
          transform-origin: left;
        }
        50% {
          transform: scaleX(1);
          transform-origin: right;
        }
        62.5% {
          transform: scaleX(0);
        }
        100% {
          transform-origin: right;
        }
      }
    }

    &:after {
      top: 0;
      right: 0;
      height: 100%;
      width: $width;
      animation: two $duration forwards infinite;

      @keyframes two {
        12.5% {
          transform: scaleY(0);
          transform-origin: top;
        }
        25% {
          transform: scaleY(1);
        }
        50% {
          transform-origin: top;
        }
        62.5% {
          transform: scaleY(1);
          transform-origin: bottom;
        }
        75% {
          transform: scaleY(0);
        }
        100% {
          transform-origin: bottom;
        }
      }
    }
  }

  .two {
    &:before {
      bottom: 0;
      right: 0;
      width: 100%;
      height: $width;
      animation: three $duration forwards infinite;

      @keyframes three {
        25.5% {
          transform: scaleX(0);
          transform-origin: right;
        }
        37.5% {
          transform: scaleX(1);
        }
        50% {
          transform-origin: right;
        }
        75% {
          transform: scaleX(1);
          transform-origin: left;
        }
        82.5% {
          transform: scaleX(0);
        }
        100% {
          transform-origin: left;
        }
      }
    }

    &:after {
      bottom: 0;
      left: 0;
      height: 100%;
      width: $width;
      animation: four $duration forwards infinite;

      @keyframes four {
        37.5% {
          transform: scaleY(0);
          transform-origin: bottom;
        }
        50% {
          transform: scaleY(1);
        }
        75% {
          transform-origin: bottom;
        }
        82.5% {
          transform: scaleY(1);
          transform-origin: top;
        }
        100% {
          transform: scaleY(0);
          transform-origin: top;
        }
      }
    }
  }
}

/***** LOADER CONTAINER STYLES *****/
/*** System Loader ***/
.system-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.system-loader img {
  animation: fadeEffect 2s ease-in infinite;
}
/* Fade Effect */
@keyframes fadeEffect {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/*** Full Page Loader ***/
.full-page-loader {
  position: relative;
  height: calc(100vh - 72px);
  top: 0;
  left: 0;
}
.full-page-loader .loader {
  height: 48px;
  width: 48px;
}

/*** Mobile Loader ***/
.mobile-loader {
  text-align: center;
  margin: 8px 0 16px 0;
}
.mobile-loader div#loading {
  height: 24px;
  width: 24px;
}

/***** LOADER SIZES *****/
.medium-loader div#loading {
  height: 64px;
  width: 64px;
}
.small-loader div#loading {
  height: 32px;
  width: 32px;
}
.btn-large-loader .loader {
  height: 48px;
  width: 48px;
}
.btn-loader .loader {
  height: 40px;
  width: 40px;
}
