/***** HOME PAGE HEADER STYLES *****/
.home-page-header {
  background: #1326f3;
  padding: 80px 0;
  margin-bottom: 80px;
}
.home-page-title {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 40px;
  text-align: center;
}
.home-page-subtitle {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .home-page-header {
    padding: 40px 16px;
    margin-bottom: 40px;
  }
  .home-page-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .home-page-subtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

/***** HOME PAGE SECTION STYLES *****/
.home-page-section-title {
  font-size: 32px;
  font-weight: bold;
}
.home-page-section-title.light-text {
  color: #fff;
}
.home-page-section-title.accent-text {
  color: $accent-color;
}
@media only screen and (max-width: 600px) {
  .home-page-section.mb-80 {
    margin-bottom: 40px;
  }
  .home-page-section-title {
    font-size: 24px;
  }
}

/***** CATEGORY CAROUSEL STYLES *****/
.carousel-wrapper {
  overflow: hidden;
}
.carousel {
  position: relative;
  display: flex;
  transition: margin-left 600ms ease-in-out;
  width: 100%;
  margin-left: calc(
    2 * (50vw / 2 - (50vw * 0.42 + 2 * 8px)) + 50vw / -2 +
      (50vw * 0.42 + 2 * 8px) / -1
  );
  &.noanimate {
    transition: margin-left 0ms ease-in-out;
  }
}
.slide {
  position: relative;
  margin: 0 12px;
  flex: 0 0 260px;
  max-width: 260px;
  height: 260px;
  color: #fff;
}
.slide-background-image {
  position: absolute;
  height: 260px;
  width: 260px;
  object-fit: cover;
  top: 0;
  left: 0;
}
.slide img {
  height: 100%;
  width: 100%;
}
.slide-content {
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  padding: 24px;
  height: 100%;
  transition: opacity 300ms linear;
}
.slide:hover .slide-content {
  opacity: 1;
  visibility: visible;
}
.carousel-nav {
  display: flex;
  justify-content: space-between;
}
.carousel-nav button {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  height: 260px;
  width: 48px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  margin-top: -260px;
  transition: opacity 300ms linear;
}
.carousel-nav button.prev {
  position: absolute;
  left: 0;
}
.carousel-nav button.next {
  position: absolute;
  right: 0;
}
.carousel-wrapper:hover button {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 1040px) {
  .slide {
    flex: 0 0 180px;
    max-width: 180px;
    height: 180px;
  }
  .slide-background-image {
    height: 180px;
    width: 180px;
  }
  .slide-content {
    opacity: 1;
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
  }
  .slide-content p {
    margin-bottom: 0;
    text-align: center;
  }
  .carousel-nav button {
    height: 180px;
    margin-top: -180px;
    width: 32px;
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (max-width: 400px) {
  .carousel {
    margin-left: calc(
      2 * (50vw / 2 - (50vw * 0.42 + 2 * 8px)) + 50vw / -2 +
        (50vw * 0.42 + 2 * 88px) / -1
    );
  }
}

/***** TWO COLUMN GRID SECTION STYLES *****/
.two-column-grid-section {
  display: grid;
  grid-template-columns: 50% 50%;
}
.two-column-grid-section-left {
  height: 100%;
}
.two-column-grid-section-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: 388px;
}
.two-column-grid-section-logo {
  position: absolute;
  height: 61px;
  width: 61px;
  top: 50%;
  left: 50%;
}
.logo-between-two-trips {
  transform: translate(-50%, -160%);
}
.logo-center {
  transform: translate(-50%, -50%);
}
.two-column-grid-section-logo img {
  height: 100%;
  width: 100%;
}
.two-column-grid-section-right {
  background: $accent-color-secondary;
  color: #fff;
  max-height: 388px;
}
.two-column-grid-section-right-content {
  max-width: 360px;
  text-align: center;
}
@media only screen and (max-width: 1040px) {
  .two-column-grid-section {
    grid-template-columns: 1fr 2fr;
  }
  .two-column-grid-section.two-column-grid-section-even {
    grid-template-columns: 50% 50%;
  }
  .two-column-grid-section-logo {
    height: 120px;
    width: 120px;
  }
  .two-column-grid-section-right {
    min-height: 280px;
  }
}
@media only screen and (max-width: 600px) {
  .two-column-grid-section {
    grid-template-columns: 1fr;
  }
  .two-column-grid-section.two-column-grid-section-even {
    grid-template-columns: 1fr;
  }
  .two-column-grid-section-left {
    height: 200px;
  }
  .two-column-grid-section-logo {
    height: 80px;
    width: 80px;
    left: 50%;
  }
  .two-column-grid-section-right {
    min-height: unset;
    justify-content: center;
    padding: 40px 16px;
  }
  .two-column-grid-section-right-content {
    margin-top: 40px;
    max-width: unset;
  }
}

/***** HOW IT WORKS STYLES *****/
.how-it-works {
  padding: 80px;
}
.how-it-works .dashed-circle-icon {
  margin-right: 72px;
  position: relative;
}
.how-it-works .vertical-connector {
  position: absolute;
  bottom: -30px;
  left: 34px;
  z-index: 2;
}
.how-it-works .horizontal-connector {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}
// .how-it-works .dashed-circle-icon:before {position: absolute; content: ""; height: 12px; width: 12px; border: 2px solid $accent-color-secondary;}
// .how-it-works .dashed-circle-icon:after {width: 32px; border-top: 2px dashed $accent-color-secondary;}
@media only screen and (max-width: 1040px) {
  .how-it-works {
    padding: 24px;
  }
  .how-it-works .relative {
    margin-right: 16px;
  }
  .how-it-works .dashed-circle-icon {
    min-height: 64px;
    height: 64px;
    min-width: 64px;
    width: 64px;
    margin-right: 32px;
    background: #fff;
    z-index: 1;
  }
  .how-it-works .dashed-circle-icon img {
    height: 32px;
  }
  .how-it-works .vertical-connector {
    left: 27px;
  }
  .how-it-works .horizontal-connector {
    right: -4px;
  }
  .how-it-works-steps .xxl-text {
    font-size: 16px;
  }
  .how-it-works-steps .medium-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .how-it-works {
    padding: 40px 16px;
  }
  .how-it-works .home-page-section-title {
    text-align: center;
  }
  .how-it-works-steps {
    width: 248px;
    margin: 0 auto;
  }
  .how-it-works .dashed-circle-icon {
    min-height: 64px;
    height: 64px;
    min-width: 64px;
    width: 64px;
    margin-right: 32px;
  }
  .how-it-works .dashed-circle-icon img {
    height: 32px;
  }
}
/*** Dashed Circle Icon Styles ***/
.dashed-circle-icon {
  height: 80px;
  width: 80px;
  border-top: 2px dashed $accent-color-secondary;
  border-bottom: 2px dashed $accent-color-secondary;
  border-left: 2px solid $accent-color-secondary;
  border-right: 2px solid $accent-color-secondary;
  border-radius: 100%;
  transform: rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashed-circle-icon > * {
  transform: rotate(45deg);
}

/***** KNOW WHATS YOURS ICONS STYLES *****/
.know-whats-yours-icons .img-container {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
@media only screen and (max-width: 600px) {
  .know-whats-yours-icons > div {
    margin-bottom: 32px;
  }
  .know-whats-yours-icons .img-container {
    margin-bottom: 8px;
  }
  .know-whats-yours-icons .xl-text {
    font-size: 16px;
  }
  .know-whats-yours-icons br {
    display: none;
  }
}

/***** KNOWLEDGE BOX STYLES *****/
.knowledge-box {
  background: $accent-color;
  min-height: 720px;
  padding: 80px;
  color: #fff;
}
.knowledge-box-content {
  max-width: 460px;
}
.knowledge-box-title {
  font-size: 72px;
  line-height: 80px;
  font-weight: 700;
  color: $accent-color-secondary;
}
@media only screen and (max-width: 1040px) {
  .knowledge-box {
    padding: 40px;
    min-height: auto;
  }
  .knowledge-box-title {
    line-height: 53px;
    font-size: 48px;
    margin-bottom: 24px;
  }
  .knowledge-box .large-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .knowledge-box {
    min-height: auto;
    padding: 40px 16px;
  }
  .knowledge-box-title {
    font-size: 40px;
    line-height: initial;
    width: 256px;
  }
}
