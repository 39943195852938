/*** Modal Styles ***/
.modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.12);
  top: 0;
  left: 0;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;
}
.modal {
  max-height: 80vh;
  overflow: auto;
  max-width: 96%;
}
.modal-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}
@media only screen and (max-width: 600px) {
  .modal-container {
    padding-bottom: 32px;
  }
}

/*** Modal Sizes Styles ***/
.small-modal {
  width: 440px;
  max-width: 96%;
}
.large-modal {
  width: 640px;
  max-width: 96%;
}

/* Transition Styles */
.example-enter {
  opacity: 0.01;
}
.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.example-leave {
  opacity: 1;
}
.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.popup-image {
  height: 100%;
  min-height: 510px;
  min-width: 616px;
  overflow: hidden;
}
.popup-text-container {
  padding: 10px;
}
.popup-small-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.38);
}
.popup-medium-text {
  font-size: 32px;
}
.popup-large-text {
  font-size: 56px;
}
.popup-container {
  margin-left: 40;
}
@media only screen and (max-width: 1040px) {
  .popup-container {
    margin-left: 0;
  }
  .popup-text-container {
    padding-left: 36px;
  }
  .popup-image {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .popup-medium-text {
    font-size: 18px;
  }
  .popup-large-text {
    font-size: 36px;
  }
}
